import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/home/HomeView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/authView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/appdetail',
    name: 'appdetail',
    component: () => import('./../views/home/AppDetail.vue')
  },
  {
    path: '/flow',
    name: 'flow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/flow/FlowView.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import(/* webpackChunkName: "about" */ '../views/platform/PlatformView.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "about" */ '../views/personal/PersonalView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((from, to, next)=>{
  if(from.path == '/auth') {
    next()
  } else {
    if(sessionStorage.getItem("token")) {
      next()
    } else {
      next("/auth")
    }
  }
})

export default router
