<template>
  <div id="app">
    <NavAsk />
    <div class="app-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavAsk from "./views/nav/NavAsk.vue";
export default {
  components: { NavAsk },
  data() {
    return {}
  }
}
</script>

<style lang="less">
@import "./assets/less/app.less";

#app {
  display: flex;
  justify-content: space-between;
  background-color: #f2f6fc;

  .ask-empty-data {
    width: 250px;
    margin: 100px auto;
    text-align: center;
    img{
      width: 250px;
    }

    .ask-empty-data-span {
      color: #A9B3C6;
    }
  }

  .nav-content {
    flex: none;
  }

  .app-content {
    flex: auto;
    width: calc(100vw - 96px);
    height: calc(100vh - 24px);
    padding: 12px 16px;
    min-width: 1000px;
    overflow-x: auto;
    text-align: left;

    .view-content {
      position: relative;
      height: calc(100vh - 110px);
    }
  }
}

.el-form-item__label {
  text-align: left !important
}

::-webkit-scrollbar {
  width: 6px;
  height: 12px;
  background-color: transparent
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #bfceec
}

.el-select-dropdown {
  z-index: 2500 !important;

  .dlhg-select-item {
    width: 200px !important;
  }
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: #366aff !important;
}

.el-select-dropdown__item.selected {
  color: #366aff !important;
}

.el-cascader__dropdown,
.el-select-dropdown,
.el-message-box__wrapper,
.el-tooltip__popper {
  z-index: 9999999999 !important;
}

.el-message {
  z-index: 99999999999 !important;
}

.el-picker-panel {
  z-index: 99999999999 !important;
}

@media screen and (max-width: 1100px) {
  body {
    font-size: 12px !important;
  }

  .bind-third-account-input {
    font-size: 12px !important;
  }

  .el-form-item__label {
    font-size: 12px !important;
  }

  .el-input__inner {
    font-size: 12px !important;
    min-height: 34px;
  }

  .el-radio__label {
    font-size: 12px !important;
  }

  .el-checkbox__label {
    font-size: 12px !important;
  }

  .el-cascader-panel {
    font-size: 12px !important;
  }

  .el-select-dropdown__item {
    font-size: 12px !important;
  }

  .el-timeline {
    font-size: 12px !important;
  }

  .el-range-input {
    font-size: 12px !important;
  }

  .el-range-separator {
    font-size: 12px !important;
  }

  .el-cascader__search-input {
    font-size: 12px !important;
  }

  .el-select-dropdown__empty {
    font-size: 12px !important;
  }

  .el-popover {
    font-size: 12px !important;
  }

  .el-textarea__inner {
    font-size: 12px !important;
  }

  .el-dropdown-menu__item {
    font-size: 12px !important;
  }

  .el-table {
    font-size: 12px !important;
  }

  #popup-assembly {
    width: 600px !important;
  }

  .isShowNotice-s {
    img {
      height: 13px;
    }
  }

  .el-tree-node__label {
    font-size: 12px !important;
  }

  .el-dropdown {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1464px) {
  body {
    font-size: 13px !important;
  }

  .bind-third-account-input {
    font-size: 13px !important;
  }

  .el-form-item__label {
    font-size: 13px !important;
  }

  .el-input__inner {
    font-size: 13px !important;
    min-height: 36px;
  }

  .el-radio__label {
    font-size: 13px !important;
  }

  .el-checkbox__label {
    font-size: 13px !important;
  }

  .el-cascader-panel {
    font-size: 13px !important;
  }

  .el-select-dropdown__item {
    font-size: 13px !important;
  }

  .el-timeline {
    font-size: 13px !important;
  }

  .el-range-input {
    font-size: 13px !important;
  }

  .el-range-separator {
    font-size: 13px !important;
  }

  .el-cascader__search-input {
    font-size: 13px !important;
  }

  .el-select-dropdown__empty {
    font-size: 13px !important;
  }

  .el-popover {
    font-size: 13px !important;
  }

  .el-textarea__inner {
    font-size: 13px !important;
  }

  .el-dropdown-menu__item {
    font-size: 13px !important;
  }

  .el-table {
    font-size: 13px !important;
  }

  #popup-assembly {
    width: 600px !important;
  }

  .isShowNotice-s {
    img {
      height: 13px;
    }
  }

  .el-tree-node__label {
    font-size: 13px !important;
  }

  .el-dropdown {
    font-size: 13px !important;
  }

  .work-order-card-item-r {
    width: 180px !important;
  }
}

@media screen and (min-width: 1465px) {
  body {
    font-size: 14px !important;
  }

  .bind-third-account-input {
    font-size: 14px !important;
  }

  .el-form-item__label {
    font-size: 14px !important;
  }

  .el-input__inner {
    font-size: 14px !important;
  }

  .el-radio__label {
    font-size: 14px !important;
  }

  .el-checkbox__label {
    font-size: 14px !important;
  }

  .el-cascader-panel {
    font-size: 14px !important;
  }

  .el-select-dropdown__item {
    font-size: 14px !important;
  }

  .el-timeline {
    font-size: 14px !important;
  }

  .el-range-input {
    font-size: 14px !important;
  }

  .el-range-separator {
    font-size: 14px !important;
  }

  .el-cascader__search-input {
    font-size: 14px !important;
  }

  .el-select-dropdown__empty {
    font-size: 14px !important;
  }

  .el-popover {
    font-size: 14px !important;
  }

  .el-textarea__inner {
    font-size: 14px !important;
  }

  .el-dropdown-menu__item {
    font-size: 14px !important;
  }

  .el-table {
    font-size: 14px !important;
  }

  .el-button--small {
    font-size: 14px !important;
  }

  #popup-assembly {
    width: 640px !important;
  }

  .isShowNotice-s {
    img {
      height: 14px;
    }
  }

  .el-tree-node__label {
    font-size: 14px !important;
  }

  .el-dropdown {
    font-size: 14px !important;
  }

  .work-order-card-item-r {
    width: 340px;
  }
}

.el-table thead {
  color: #909399;
  font-weight: 500
}
</style>
