<template>
    <div id="gr-right-content-bottom-b">
        <div v-if="activeServiceObj.OPEN != -1" @click="enterModule(activeServiceObj.OPEN, 'OPEN')"
            style="color: #366aff; background: #eff3ff"
            :class="['gr-left-cbb-c-o', userCompany && userCompany.modules && userCompany.modules.length > 4 ? 'new-gr-left-cbb-c' : 'gr-left-cbb-c ', uid == 20281 || uid == 117431 ? 'jd' : '']">

            <span class="icon">
                <i class="iconfont guoran-askbotdandutubiao2"></i>
            </span>
            <span class="a-text">{{ uid == 20281 || uid == 117431 ? '智能机器人' : 'AskBot对话机器人' }}</span>
        </div>
        <div v-if="activeServiceObj.WORKORDER != -1" @click="enterModule(activeServiceObj.WORKORDER, 'WORKORDER')"
            style="color: #7367f0; background: #efedff"
            :class="['gr-left-cbb-c-w', userCompany && userCompany.modules && userCompany.modules.length > 4 ? 'new-gr-left-cbb-c' : 'gr-left-cbb-c ', uid == 20281 || uid == 117431 ? 'jd' : '']">

            <span class="icon">
                <i class="iconfont guoran-askservice-logo03"></i>
            </span>
            <span class="a-text">{{ uid == 20281 || uid == 117431 ? '工单系统' : 'AskService工单系统' }}</span>
        </div>
        <div v-if="activeServiceObj.IM != -1" @click="enterModule(activeServiceObj.IM, 'IM')"
            style="color: #09ddd5; background: #e1faf9"
            :class="['gr-left-cbb-c-i', userCompany && userCompany.modules && userCompany.modules.length > 4 ? 'new-gr-left-cbb-c' : 'gr-left-cbb-c ', uid == 20281 || uid == 117431 ? 'jd' : '']">

            <span class="icon">
                <i class="iconfont guoran-guanlixitong1"></i>
            </span>
            <span class="a-text"> {{ uid == 20281 || uid == 117431 ? '即时通讯IM' : 'AskChat即时通讯IM' }}</span>
        </div>
        <div v-if="activeServiceObj.KNOWLEDGE != -1 && userInfo.sysEntrance && userInfo.sysEntrance.knowledge"
            @click="enterModule(activeServiceObj.KNOWLEDGE, 'KNOWLEDGE')" style="color: #555555; background: #F8F8F8"
            class="gr-left-cbb-c-k"
            :class="userCompany && userCompany.modules && userCompany.modules.length > 4 ? 'new-gr-left-cbb-c' : 'gr-left-cbb-c '">
            <span class="icon">
                <img src="./../../assets/images/knowledge.png">
            </span>
            <span class="a-text">AskBot知识管理</span>
        </div>
        <popup v-if="overdueDialog" @closeEvent="overdueDialog = false">
            <div slot="popup-name" class="popup-name">版本过期</div>
            <div slot="popup-tip">系统版本已过期，请及时续费</div>
            <div slot="popup-con">
                <el-carousel height="200px">
                    <el-carousel-item v-for="(item, indexUrl) in imageURls" :key="indexUrl">
                        <div class="image-outer">
                            <div class="image-o-inner">
                                <img height="200px" width="260px" :src="item" alt="" srcset="" />
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="overdueDialog = false">我知道了</el-button>
                <el-button type="primary" class="btn" style="color: #ffffff" v-clipboard:copy="'4001-789-800'"
                    v-clipboard:success="onCopy" v-clipboard:error="onError">拨打 4001-789-800 续费</el-button>
            </div>
        </popup>
        <popup v-if="degradeDialog" :showCloseBtn="true" @closeEvent="degradeDialog = false">
            <div slot="popup-name" class="popup-name">降级免费版</div>
            <div slot="popup-tip">AskService降级为免费版</div>
            <div slot="popup-con">
                <div class="image-outer">
                    <div class="image-o-inner">
                        <img height="200px" width="260px"
                            src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/gr-bot-f-r-bg.png"
                            alt="" srcset="" />
                    </div>
                </div>
                <div class="degrade-des">
                    暂时无法访问AskService，联系企业系统管理员对服务版本进行确认后才能继续使用。
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button class="btn" @click="signOut">退出登录</el-button>
                <el-button type="primary" class="btn" style="color: #ffffff" @click="copyManageInfo">点击复制管理员联系方式</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "./popup.vue";
export default {
    props: ["activeServiceObj", "botExpiresIn", "userCompany", "userInfo"],
    components: { Popup },
    data() {
        return {
            overdueDialog: false,
            degradeDialog: false,
            manageInfo: "我就是联系方式",
            imageURls: [
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/gr-it-f-r-bg.png",
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/gr-hr-f-r-bg.png",
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/images/gr-vea-f-r-bg.png",
            ],
            uid: ""
        };
    },
    methods: {
        // Copy成功
        onCopy() {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError() {
            this.$message.error("抱歉，复制失败！");
        },
        // 复制管理员联系信息
        copyManageInfo() {
            let info = this.manageInfo; //拿到想要复制的值
            let copyInput = document.createElement("input"); //创建input元素
            document.body.appendChild(copyInput); //向页面底部追加输入框
            copyInput.setAttribute("value", info); //添加属性，将url赋值给input元素的value属性
            copyInput.select(); //选择input元素
            document.execCommand("Copy"); //执行复制命令
            this.$message.success("复制成功！"); //弹出提示信息，不同组件可能存在写法不同
            //复制之后再删除元素，否则无法成功赋值
            copyInput.remove(); //删除动态创建的节点
        },

        // 弹窗底部按钮退出系统
        signOut() {
            this.$http.delete("/api/sso/auth/sign-out").then((res) => {
                if (res.status === 200) {
                    this.$router.push({
                        name: "CheckAuth",
                    });
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        enterModule(redirectUrl) {
            if (this.activeServiceObj.STATUS !== 2) {
                let text = "";
                switch (this.activeServiceObj.STATUS) {
                    case 1:
                        text =
                            "请等待审核，我们将在1~2个工作日内进行审核，您也可以联系4001-789-800加速审核。";
                        break;
                    case 3:
                        text = "认证未通过，您也可以联系4001-789-800咨询。";
                        break;
                    case 0:
                        text = "未认证，请认证或联系4001-789-800咨询。";
                        break;
                    default:
                        break;
                }
                if (text !== "") {
                    this.$message({
                        message: text,
                        type: "warning",
                        duration: 5000,
                    });
                }
            } else {
                // if (type == "OPEN" && this.botExpiresIn == 0) {
                //     this.overdueDialog = true;
                //     return false;
                // }
                let tempwindow = window.open("_blank");
                tempwindow.location = redirectUrl;
            }
        },
    },
    mounted() {
        this.uid = localStorage.getItem('_uid')
    }
};
</script>
<style lang="less">
#gr-right-content-bottom-b {
    display: flex;
    justify-content: flex-start;

    .degrade-des {
        margin: 25px 0 0;
        text-align: center;
        font-size: 12px;
        color: #a9b3c6;
    }

    .image-outer {
        width: 100%;
        height: 200px;

        .image-o-inner {
            width: 260px;
            margin: 0 auto;
        }
    }

    .gr-left-cbb-c-o:hover {
        border: solid 1px #366aff;
    }

    .gr-left-cbb-c-w:hover {
        border: solid 1px #7367f0;
    }

    .gr-left-cbb-c-i:hover {
        border: solid 1px #09ddd5;
    }

    .gr-left-cbb-c-k:hover {
        border: 1px solid #bfbfbf;
    }

    .gr-left-cbb-c {
        flex: 1;
        min-width: 150px;
        text-align: center;
        margin: 0 14px;
        background: #eff3ff;
        border-radius: 6px;
        height: 105px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 1px solid #FFFFFF;

        .icon {
            margin-top: 20px;

            i {
                font-size: 40px;
            }

            img {
                width: 37px;
                height: 37px;
            }
        }

        .a-text {
            font-size: 13px;
            margin-top: 6px;
        }
    }

    .jd {
        align-items: center;
        justify-content: center;
    }

    .new-gr-left-cbb-c {
        flex: 1;
        text-align: center;
        background: #eff3ff;
        border-radius: 6px;
        display: flex;
        cursor: pointer;
        border: 1px solid #FFFFFF;

        .a-text {
            font-size: 13px;
            margin-top: 6px;
        }

        .jd-logo {
            width: 60px;
            height: auto;
        }
    }
}

@media screen and (max-width: 1200px) {
    #gr-right-content-bottom-b {
        flex-wrap: wrap;
    }

    .new-gr-left-cbb-c {
        min-width: 210px;
        height: 50px;
        margin: 0 14px;
        align-items: center;
        justify-content: center;

        .icon {
            margin-right: 16px;

            i {
                font-size: 30px;
            }

            img {
                height: 30px;
                width: 30px;
            }
        }
    }

    .new-gr-left-cbb-c:nth-child(1) {
        margin-bottom: 16px;
    }

    .new-gr-left-cbb-c:nth-child(2) {
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 1200px) {
    .new-gr-left-cbb-c {
        min-width: 150px;
        flex-direction: column;
        margin: 0 14px;
        height: 105px;

        .icon {
            margin-top: 20px;

            i {
                font-size: 40px;
            }

            img {
                height: 37px;
                width: 37px;
            }
        }
    }
}
</style>