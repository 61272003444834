import axios from 'axios';
import { ssoCookieChanged } from './cookie';
import { isInnerIP } from './ip';

const instance = axios.create();

let doingReSetStorage = false;

function doReSetStorage(onSuccess, onException, token) {
    if (doingReSetStorage) {
        return;
    }
    doingReSetStorage = true;
    let url = '/api/auth/by-sso-token';
    if (token != null) {
        url = url + '?token=' + token;
    }

    instance.post(url)
        .then(() => {
            // console.log(21, res.data.data.userId)
            // localStorage.setItem("_uid", res.data.data.userId);
            // localStorage.setItem('_mainId', res.data.data.mainId)
            // localStorage.setItem('_mainId', res.data.data.mainId)
            // localStorage.setItem("accountType", res.data.data.accountType);
            // localStorage.setItem("roleId", res.data.data.roleId);
            // localStorage.setItem("username", res.data.data.username);
            // localStorage.setItem("realname", res.data.data.realName);
            // localStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);
            // localStorage.setItem('rolePrivileges', JSON.stringify(res.data.data.rolePrivileges))
            // initSsoUid();
            // doingReSetStorage = false;
            if (onSuccess) {
                onSuccess();
            }
        })
        // .catch((err) => {
        //     doingReSetStorage = false;
        //     console.info('do auth exception', err);
        //     if (onException) {
        //         onException(err);
        //     }
        // });
}

function doCheckAndRestStorageInfo() {
    if (isInnerIP) {
        return;
    }
    if (ssoCookieChanged()) {
        doReSetStorage();
    }
}

export { doCheckAndRestStorageInfo, doReSetStorage }