import axios from 'axios';

// import { redirect2LoginPage } from "./redirectUrl";
import { doCheckAndRestStorageInfo } from "./auth";

var instance = axios.create();


instance.interceptors.request.use(
    config => {
        doCheckAndRestStorageInfo();
        let token = localStorage.getItem('_token');
        config.headers.common.Authorization = 'Bearer ' + token;
        config.headers.common.token = token;
        config.headers.common.mainId = localStorage.getItem('_mainId');
        config.headers.common.uid = localStorage.getItem('_uid');
        if (localStorage.getItem('_mainId') == null || localStorage.getItem('_mainId') == '') {
            // location.hash = "/auth";
            // location.hash = "/home";
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//axios response 拦截器
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // if (error.response.status === 403 || error.response.code == '5000') {
        //     redirect2LoginPage();
        // } else {
            return Promise.reject(error)
        // }
    }
);

export default instance;