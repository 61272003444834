<template>
  <div class="home">
    <top-header>
      <div slot="setup-header-left" class="setup-header-left">
        <span class="setup-header-left-icon">
          <i class="iconfont guoran-pingtai"></i></span>
        <span>AI Agent平台</span>
      </div>
      <div class="setup-header-right-l" slot="setup-header-right-l">
        <el-button size="small" type="primary" @click="addApp">
          <i class="iconfont guoran-a-16-13"></i>
          创建应用</el-button>
      </div>
    </top-header>
    <div class="view-content">
      <div class="view-content-home-serch">
        <el-input placeholder="搜索系统" class="serch_input" size="mini" prefix-icon="el-icon-search" v-model="serchText"
          @keyup.enter.native="(e) => {
            return getTableInfo();
          }
            ">
        </el-input>
        <div class="sort-order">
          <div class="sort-order-l" @click="openSortView">
            <i class="iconfont guoran-a-16-08"></i>
            <span>排序方式</span>
          </div>
          <div class="sort-order-r" @click="openSortView">
            <span>{{ sortByTimeText.join() }}<span>&nbsp;</span></span>
            <i :class="sortIcon"></i>
          </div>
          <div class="sort-order-r-select">
            <template v-if="sortIcon == 'el-icon-arrow-up'">
              <div class="sort-order-r-select-item" v-for="item in sortList" :key="item.value"
                @click="sortSelectItem(item)" :style="{
                  color:
                    sortSelectChecked[item.value] == item.value
                      ? '#759FFF'
                      : '#000000',
                }">
                <span>{{ item.name }}</span>
                <span><i v-if="sortSelectChecked[item.value] == item.value" class="el-icon-check"></i></span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <el-table :data="tableData" @row-click="navigateToDetail" class="table" v-loading="tableLoading"
        style="width: 100%">
        <el-table-column align="center" label="应用图标" width="100">
          <template slot-scope="scope">
            <div class="table-app-icon-box">
              <div class="table-app-icon">
                <img v-if="scope.row.logo" height="32px" width="32px" :src="scope.row.logo" alt="" srcset="">
                <img v-else height="32px" width="32px" src="https:\/\/static.guoranbot.com/botProfilePhoto/10.png" alt=""
                  srcset="">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="应用名称">
        </el-table-column>
        <el-table-column prop="version" width="140px" label="当前版本">
          <template slot-scope="scope">
            <span>{{ scope.row.version == null ? '1.0.0' : scope.row.version }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" label="创建人" width="200px" show-overflow-tooltip>
          <template slot-scope="scope">
            <section class="serctionImg_view">
              <img v-if="scope.row.creatorUrl" :src="scope.row.creatorUrl" draggable="false" width="30px" height="30px" />
              <div class="div_headphoto" v-else>
                {{ scope.row.creator && scope.row.creator.name ? scope.row.creator.name[0] : '无' }}
              </div>
              <span>
                {{ scope.row.creator.name }}
              </span>
            </section>
          </template>
        </el-table-column>
        <el-table-column label="发布状态" width="100">
          <template slot-scope="scope">
            <span v-if="!!scope.row.release" size="mini" type="success"> <span
                class="squ-status squ-status-active"></span>
              已发布</span>
            <span v-else size="mini" type="info"> <span class="squ-status squ-status-dis"></span> 未发布</span>
          </template>
        </el-table-column>
        <el-table-column label="可见范围" width="100">
          <template slot-scope="scope">
            <span class="view-range-personal" v-if="scope.row.visibleScope === 'PRI'">私有</span>
            <span v-else class="view-range-public">公开</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="创建时间" width="160px">
          <template slot-scope="scope">
            <div class="atc-time">
              <i class="el-icon-time"></i>
              <div style="text-align: center">{{ dateFormat(scope.row.createTime) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="160px">
          <template slot-scope="scope">
            <div class="atc-time">
              <i class="el-icon-time"></i>
              <div style="text-align: center">{{ dateFormat(scope.row.updateTime) }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <gr-pagination ref="grpage" :pageSize="packFromSize" :currentPage="packFromPage" :total="packFromTotal"
        @currentChange="handleCurrentChange"></gr-pagination>
    </div>
    <gr-popup v-if="addAppVisible" @closeEvent="addAppVisible = false">
      <div slot="popup-name" class="popup-name">创建应用</div>
      <div slot="popup-tip">填写应用基本信息</div>
      <div slot="popup-con">
        <add-app ref="addapp" handleType="add" @closeAddApp="closeAddApp" @getTableInfo="getTableInfo"></add-app>
      </div>
      <div slot="dialog-footer" class="service-dialog-footer">
        <el-button @click="addAppVisible = false">取消</el-button>
        <el-button type="primary" @click="saveAppInfo">保存</el-button>
      </div>
    </gr-popup>
  </div>
</template>

<script>
import TopHeader from "./../nav/TopHeader.vue";
import GrPagination from "./../../components/GrPagination.vue";
import GrPopup from "./../../components/GrPopup.vue";
import AddApp from "./components/AddApp.vue";
export default {
  name: 'HomeView',
  components: {
    TopHeader,
    GrPagination,
    GrPopup,
    AddApp
  },
  data() {
    return {
      tableLoading: false,
      serchText: "",
      sortList: [
        {
          name: "创建时间",
          value: "createTime",
          flag: false,
        },
        {
          name: "更新时间",
          value: "updateTime",
          flag: true,
        },
        {
          name: "升序排列",
          value: "ASC",
          flag: false,
        },
        {
          name: "降序排列",
          value: "DESC",
          flag: true,
        },
        // {
        //   name: "相同产品合并",
        //   value: "grouped",
        //   flag: false,
        // },
      ],
      sortSelectChecked: {
        createTime: "",
        updateTime: "updateTime",
        DESC: "",
        ASC: "ASC",
      },
      sortIcon: "el-icon-arrow-down",
      packFromSize: 20,
      packFromPage: 0,
      packFromTotal: 0,
      selectList: [],
      tableData: [],
      addAppVisible: false,
    }
  },
  methods: {
    navigateToDetail(row) {
      console.log(row);
      localStorage.setItem('_askActiveAppInfo', JSON.stringify(row));
      this.$router.push({
        name: "appdetail",
        query: { id: row.id }
      })
    },
    addApp() {
      this.addAppVisible = true;
    },
    closeAddApp() {
      this.addAppVisible = false;
    },
    getTableInfo() {
      let param = {
        sortByProperty: this.sortSelectChecked.createTime == "createTime" ? "createTime" : "updateTime",
        direction: this.sortSelectChecked.DESC == "" ? "ASC" : "DESC",
        size: this.packFromSize,
        page: this.packFromPage,
        keyWord: this.serchText
      };

      sessionStorage.setItem("lastAppParam", JSON.stringify(param));
      console.log(param);
      this.tableLoading = true;
      this.$http.get(this.requestUrl.app.appList + "?page=" + param.page + "&size=" + param.size + "&direction=" + param.direction + "&sortByProperty=" + param.sortByProperty + "&keyWord=" + param.keyWord).then(res => {
        if (res !== null && res !== 'null') {
          console.log(res);
          this.tableData = res.data.content;
          this.packFromTotal = res.data.totalElements;
          this.$refs.grpage.totalC = this.packFromTotal;
          console.log(218, this.packFromTotal);
          this.tableLoading = false;
        } else {
          // TODO 提示信息
        }
      })
      // $HTTP 获取列表数据
    },
    handleCurrentChange(value) {
      this.packFromPage = value - 1;
      this.getTableInfo();
    },
    openSortView(e) {
      console.log(e);
      let select = document.getElementsByClassName("sort-order-r-select")[0];
      if (select.style.display == "block") {
        select.style.display = "none";
        select.style.height = "0px";
        this.sortIcon = "el-icon-arrow-down";
      } else {
        select.style.display = "block";
        setTimeout(() => {
          select.style.height = "150px";
          select.style.padding = "10px 0";
        }, 0);
        this.sortIcon = "el-icon-arrow-up";
      }
    },
    sortSelectItem(item) {
      if (item.value == "grouped" && this.sortSelectChecked.grouped) {
        this.sortSelectChecked.grouped = "";
      } else {
        this.sortSelectChecked[item.value] = item.value;
      }
      this.sortSelectChecked[item.value] == "createTime" &&
        (this.sortSelectChecked.updateTime = "");
      this.sortSelectChecked[item.value] == "updateTime" &&
        (this.sortSelectChecked.createTime = "");
      this.sortSelectChecked[item.value] == "DESC" &&
        (this.sortSelectChecked.ASC = "");
      this.sortSelectChecked[item.value] == "ASC" &&
        (this.sortSelectChecked.DESC = "");
      this.packFromPage = 0;
      this.selectList = [];
      this.getTableInfo();
    },
    dateFormat(time) {
      let date = new Date(time);
      let year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      // let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      // let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "-" + month + "-" + day + " ";
    },
    saveAppInfo() {
      this.$refs.addapp.cSaveAppInfo('add');
    }
  },
  computed: {
    sortByTimeText() {
      let arr = [];
      let keyArray = Object.keys(this.sortSelectChecked);
      arr = keyArray.filter((item) => {
        return (
          this.sortSelectChecked[item] !== "" &&
          this.sortSelectChecked[item] !== "grouped" &&
          this.sortSelectChecked[item]
        );
      });
      return arr.map((element) => {
        if (element == "createTime") return "创建时间";
        if (element == "updateTime") return "更新时间";
        if (element == "ASC") return "升序";
        if (element == "DESC") return "降序";
      });
    },
  },
  mounted() {
    if (this.$route.query.from === "detail") {
      if (sessionStorage.getItem("lastAppParam")) {
        let param = JSON.parse(sessionStorage.getItem("lastAppParam"));
        this.sortSelectChecked = {
          createTime: param.timeType === "createTime" ? "createTime" : "",
          updateTime: param.timeType === "updateTime" ? "updateTime" : "",
          DESC: param.sortType === "DESC" ? "DESC" : "",
          ASC: param.sortType === "ASC" ? "ASC" : "",
        }
        console.log(this.sortSelectChecked);
        // this.packFrom = {
        //   size: param.size,
        //   page: param.page,
        //   total: param.total
        // }
        this.serchText = (param.keyword == 'undefined' || !param.keyword) ? '' : param.keyword;
      }
    }
    this.getTableInfo();
  }
}
</script>
<style lang="less" scoped>
.view-content {
  box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
  background-color: white;
  border-radius: 6px;

  /deep/.table {
    overflow-y: auto;
    color: #000;
    height: calc(100% - 109px);

    th {
      color: #000;
      font-weight: bold;
    }

    .atc-time {
      display: flex;
      align-content: center;
      line-height: 23px;

      i {
        line-height: 23px;
        margin-right: 8px;
      }
    }

    .view-range-personal {
      display: inline-block;
      text-align: center;
      width: 55px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      background: #FFEEE0;
      border-radius: 11px;
      color: #FF7200;
    }

    .view-range-public {
      display: inline-block;
      text-align: center;
      width: 55px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      background: #ECF1FF;
      border-radius: 11px;
      color: #366AFF;
    }

    .serctionImg_view {
      display: flex;
      align-items: center;
      overflow: hidden;

      img {
        flex: none;
        border-radius: 50%;
        margin-right: 5px;
      }

      .div_headphoto {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 8px;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        font-size: 14px;
        background: #366aff;
      }

      span {
        // float: left;
        width: 70px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .squ-status {
      display: inline-block;
      width: 9px;
      height: 9px;
      border-radius: 2px;
      margin-right: 5px;
    }

    .squ-status-active {
      background: #366AFF;
    }

    .squ-status-dis {
      background: #AAB9E1;
    }

    // height: 100%;
    .el-table__expanded-cell {
      padding: 0 0 0 107px !important;
      background: #fafbfd !important;
    }

    .el-table__row {
      cursor: pointer;
    }

    .el-table__body-wrapper {
      height: calc(100% - 78px);
      overflow-y: auto;
    }

    // .el-table__expanded-cell:hover {
    //   background: #fafbfd;
    // }
    .el-table__header-wrapper {
      .el-table__header {
        .has-gutter {
          tr th {
            background: #f6f8fd !important;
          }
        }
      }
    }

    .table-app-icon-box {
      .table-app-icon {
        overflow: hidden;
        margin-left: 12px;
        height: 32px;
        width: 32px;
        border-radius: 5px;
        background-color: #DEE3EE;
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          border-radius: 6px;
        }
      }
    }

  }

  .view-content-home-serch {
    width: calc(100% - 40px);
    height: 70px;
    margin-left: 18px;
    // background-color: #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /deep/ .serch_input {
      width: calc(100% - 320px);

      .el-input__inner {
        border-radius: 0;
        border: none;
        border-left: 1px solid #e0e6f7;
      }
    }

    .sort-order {
      width: 180px;
      height: 35px;
      background: #ffffff;
      border: 1px solid #e0e6f7;
      border-radius: 5px;
      color: #616161;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      margin-right: 9px;
      position: relative;
      cursor: pointer;

      .iconfont {
        margin-right: 4px;
      }

      span {
        font-size: 12px;
      }

      .sort-order-l {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .iconfont {
          margin-right: 4px;
        }
      }

      .sort-order-r {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-weight: 600;
        }
      }

      .sort-order-r-select {
        position: absolute;
        top: 40px;
        left: 0;
        width: 190px;
        height: 0px;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        /* Firefox 4 */
        -webkit-transition: all 0.5s;
        /* Safari 和 Chrome */
        -o-transition: all 0.5s;
        /* Opera */
        background: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(57, 63, 79, 0.21);
        border-radius: 5px;
        z-index: 999999;
        overflow: hidden;
        // padding: 10px 0;
        display: none;

        .sort-order-r-select-item {
          height: 36px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          justify-content: space-between;
        }

        .active-select {
          // background-color: #759FFF;
          color: #759fff;
        }

        .sort-order-r-select-item:nth-child(2) {
          border-bottom: 1px solid #e0e6f7;
        }
      }
    }

    .head_btn {
      width: 100px;
      height: 33px;
      background: #ffffff;
      padding: 0 5px;
      border: 1px solid #a1b9ff;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      display: flex;
      background: #366aff;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
