<template>
    <div class="gr-add-app">
        <div class="gaa-cell">
            <div class="gaa-cell-title">
                <em>*</em>
                应用名称
            </div>
            <div class="gaa-cell-input">
                <el-input v-model="name" placeholder="请输入应用名称"></el-input>
            </div>
        </div>
        <div class="gaa-cell">
            <div class="gaa-cell-title">
                应用描述
            </div>
            <div class="gaa-cell-input">
                <el-input type="textarea" :rows="2" placeholder="请输入应用描述" v-model="desc">
                </el-input>
            </div>
        </div>
        <div class="gaa-cell">
            <div class="gaa-cell-title">
                <em>*</em>
                应用分类
            </div>
            <div class="gaa-cell-input">
                <el-select v-model="appClass" placeholder="请选择应用分类">
                    <el-option v-for="item in classOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="gaa-cell">
            <div class="gaa-cell-title">
                可见范围
            </div>
            <div class="gaa-cell-input">
                <el-select v-model="visibleScope" placeholder="请选择可见范围">
                    <el-option v-for="item in visibleScopeOptions" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="gaa-cell">
            <div class="gaa-cell-title">
                <!-- <em>*</em> -->
                应用主页
            </div>
            <div class="gaa-cell-input">
                <el-input v-model="homepage" placeholder="请输入应用主页"></el-input>
            </div>
        </div>
        <div class="gaa-cell">
            <div class="gaa-cell-title">
                应用LOGO
            </div>
            <div class="gaa-cell-input">
                <div class="answer-image">
                    <div class="answer-image-pre" v-if="showPreview">
                        <div class="answer-image-pre-inner" @click="showPreview = false">
                            <span class="el-icon-close"></span>
                        </div>
                        <img style="max-height: 70vh; max-width: 70vw" :src="previewImageUrl" alt srcset />
                    </div>
                    <div class="answer-image-el-upload">
                        <el-upload class="avatar-uploader" :show-file-list="false" action="" :http-request="((data) => {
                            return aliyunOssRequest(data, 'ask')
                        })" :before-upload="(file) => beforeUploadVideo(file, index)" :on-success="(res, file) =>
    handleVideoSuccess(res, file, index)
    " :on-remove="handleRemove" :auto-upload="true" accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.gif,.GIF">

                            <div class="avatar-box" v-if="logoUrl">
                                <img :src="logoUrl" class="avatar" />
                                <div class="handle-box">
                                    <i @click.stop="viewImage(logoUrl)" class="el-icon-search"></i>
                                    <i slot="trigger" class="el-icon-refresh-right"></i>
                                </div>
                            </div>

                            <div slot="trigger" class="no-url-upload" v-else>
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                        </el-upload>
                    </div>
                    <div class="answer-image-limit">
                        LOGO为方形的PNG文件
                        <br>
                        尺寸为256x256像素
                        <br>
                        不要在LOGO中包含应用名称
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ossConfig, multipartUpload, ossFileUrl } from "./../../../http/AliyunIssUtilWithProcess"
export default {
    name: "GrPopup",
    props: {
        showCloseBtn: {
            type: Boolean,
            default: true
        },
        handleType: {
            type: String,
            default: "add"
        },
        activeAppInfo: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            name: "",
            desc: "",
            appClass: "",
            classOptions: [
                { value: 'AI', label: "ai人工智能" },
                { value: 'OA', label: "协同办公系统" },
                { value: 'B2C', label: "电商平台" },
                { value: 'PRIVATEAPP', label: "私有应用" },
                { value: 'ERP', label: "财务/资源管理(ERP)" },
                { value: 'CRM', label: "客户关系管理(CRM)" },
                { value: 'MESSAGING', label: "邮件/短信服务" },
                { value: 'CMS', label: "内容管理系统" },
                { value: 'UEBA', label: "用户行为分析" },
            ],
            visibleScope: "PRI",
            visibleScopeOptions: [{ value: 'PRI', label: "私有" }, { value: "PUB", label: "公开" }],
            homepage: "",
            importFileUrl: location.origin + "/api/oss",
            showPreview: false,
            previewImageUrl: "",
            logoUrl: "",
            filenName: ''
        }
    },
    methods: {
        cSaveAppInfo(type, callback) {
            if (this.name.trim() === "") {
                this.$message({
                    message: '请填写应用名称！',
                    type: 'warning'
                });
                return
            }
            if (this.appClass === "") {
                this.$message({
                    message: '请填写应用分类！',
                    type: 'warning'
                });
                return
            }
            // if (this.homepage === "") {
            //     this.$message({
            //         message: '请填写应用主页！',
            //         type: 'warning'
            //     });
            //     return
            // }

            const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
            if (this.homepage.trim() !== '' && !reg.test(this.homepage.trim())) {
                this.$message({
                    message: '应用主页地址格式错误！',
                    type: 'warning'
                });
                return
            }
            let param = {
                name: this.name,
                desc: this.desc,
                type: this.appClass,
                homepageHref: this.homepage,
                logo: this.logoUrl,
                visibleScope: this.visibleScope
            }
            if (type === 'add') {
                this.$http.post(this.requestUrl.app.addApp, param).then(res => {
                    console.log(res, '=======');
                    if (res !== null && res !== 'null') {
                        // $HTTP 新增app 编辑保存app
                        // if (this.handleType === "edit") {
                        //     this.$emit("getTableInfo");
                        // } else {
                        //     param.id = this.activeAppInfo.id;
                        // }
                        this.$emit("getTableInfo");
                        this.$emit("closeAddApp");
                    } else {
                        // TODO 提示信息
                    }
                })
            } else {
                this.$http.put(this.requestUrl.app.updateApp + this.activeAppInfo.id, param).then(res => {
                    console.log(res, '=======');
                    if (res !== null && res !== 'null') {
                        // $HTTP 新增app 编辑保存app
                        // if (this.handleType === "edit") {
                        //     this.$emit("getTableInfo");
                        // } else {
                        //     param.id = this.activeAppInfo.id;
                        // }
                        this.$emit("getTableInfo");
                        this.$emit("closeAddApp");
                        param.id = this.activeAppInfo.id;
                        callback(param);
                    } else {
                        // TODO 提示信息
                    }
                })
            }
        },
        viewImage(url) {
            this.previewImageUrl = url;
            this.showPreview = true;

        },

        aliyunOssRequest(data) {
            let file = data.file;
            let res = multipartUpload(ossConfig, file, () => {

            }, () => { }, true);
            // console.debug('oss upload res', data, res);
            return res;
        },
        //上传前回调
        beforeUploadVideo(file, index) {
            // console.log(file, index);
            console.log(file.type, index);
            var fileSize = file.size / 1024 / 1024 < 2;
            const isJPG =
                file.type === "image/JPG" ||
                file.type === "image/JPEG" ||
                file.type === "image/PNG" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/GIF" ||
                file.type === "image/gif";
            if (
                !isJPG
            ) {
                "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                return false;
            }
            if (!fileSize) {
                this.$message.error("请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！");
                return false;
            }
        },
        //进度条
        uploadVideoProcess(event, file, fileList, activeVideoIndex) {
            console.log(file, fileList, activeVideoIndex);
        },
        //上传成功回调
        handleVideoSuccess(res, file, activeVideoIndex) {
            console.log(res, file, activeVideoIndex);
            //前台上传地址
            if (file.status == "success") {
                this.logoUrl = ossFileUrl(ossConfig, res.name);
                this.filenName = file.name;
            } else {
                this.$message.error("上传失败，请重新上传");
            }
        },
        handleRemove() {
            this.logoUrl = '';
            this.filenName = '';
        }
    },
    mounted() {
        if (this.handleType === "edit") {
            this.name = this.activeAppInfo.name;
            this.desc = this.activeAppInfo.desc;
            this.appClass = this.activeAppInfo.type;
            this.homepage = this.activeAppInfo.homepageHref?this.activeAppInfo.homepageHref:'';
            this.logoUrl = this.activeAppInfo.logo;
        }
    },
}
</script>

<style lang="less" scoped >
.answer-image-pre {
    display: flex;
    flex-direction: column;
    align-items: center;

    .answer-image-pre-inner {
        width: 100%;
        text-align: right;
        margin-right: 50px;
    }
}

.gaa-cell {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .gaa-cell-title {
        line-height: 30px;
        font-weight: 500;

        em {
            color: red;
        }
    }

    .gaa-cell-input {
        .el-select {
            width: 100%;
        }

        .answer-image {
            margin-top: 5px;
            margin-bottom: 25px;
            display: flex;
            justify-content: space-between;

            .answer-image-pre {
                width: 100vw;
                height: 100vh;
                z-index: 10000;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);

                .answer-image-pre-inner {
                    color: white;
                    font-size: 30px;
                    padding: 30px 30px 15vh;
                    text-align: right;
                    cursor: pointer;
                }
            }

            .answer-image-el-upload:hover {
                .handle-box {
                    display: flex;
                }
            }

            .answer-image-el-upload {
                position: relative;
                width: 107px;
                border-radius: 5px;

                .handle-box {
                    top: 0px;
                    position: absolute;
                    height: 107px;
                    width: 107px;
                    border-radius: 5px;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: none;
                    justify-content: space-evenly;
                    align-items: center;

                    i {
                        font-size: 22px;
                        color: white;
                    }
                }
            }

            .avatar-box {
                background: #fafbff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                overflow: hidden;
                height: 105px;
                width: 105px;
                text-align: center;
                display: flex;
                align-items: center;
            }

            .avatar {
                height: 105px;
                width: 105px;
                border-radius: 5px;
            }

            .answer-image-limit {
                color: #a9b3c6;
                font-size: 13px;
                flex: auto;
                margin-left: 20px;
                line-height: 30px;
            }

            .no-url-upload {
                width: 105px;
                height: 105px;
                background: #fafbff;
                border: 1px solid #e0e6f7;
                border-radius: 5px;
                text-align: center;
                line-height: 105px;
                font-size: 22px;
                color: #87a9ff;
            }
        }
    }
}
</style>