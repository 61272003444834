const requestUrl={
    app: {
        updateApp: '/api/dev-app/update/',
        addApp: '/api/dev-app/create',
        appList: '/api/dev-app/page',
    },
    version: {
        publish: '/api/publish/', // POST
        getList: '/api/publish/list', // GET
    },
    appAuthConfig: {
        getConfig: '/api/dev-app-auth-config/', // GET
        updateConfig: '/api/dev-app-auth-config/', // PUT
        addConfig: '/api/dev-app-auth-config/create', // POST
    },
    variable: {
        updateVariable: '/api/variable/update', // PUT
        addVariable: '/api/variable/save', // POST
        getSelfVariableList: '/api/variable/list/', // GET
        getSystemVariableList: '/api/variable/system/', // GET
        deleteVariable: '/api/variable/remove/', // DELETE
        getAllChatEntity: '/api/entity/official', // GET 获取全部官方实体
        getInstallChatEntity: '/api/entity/installed', // GET 获取已安装的实体
        deleteInstallChatEntity: '/api/entity', // DELETE 移除已安装的实体
        installChatEntity: '/api/entity', // POST 安装实体
    },
    actionList: {
        sortAction: '/api/action-list/resort', // PUT
        getActionList: '/api/action-list/list/', // GET
        createAction: '/api/action-list/create-action', // GET
        copyAction: '/api/action-list/copy/', // GET
        deleteAction: '/api/action-list/', // DELETE
    },
    actionMsg: {
        updateActionMsg: '/api/action-msg/updateConfig', // PUT
        saveActionMsg: '/api/action-msg/saveConfig', // POST
        getActionMsg: '/api/action-msg/config', // GET
    },
    actionField: {
        updateActionField: '/api/action-field/update', // PUT
        addActionField: '/api/action-field/create', // POST
        getFieldList: '/api/action-field/list', // GET
        deleteActionField: '/api/action-field/delete/', // DELETE
        saveActionField: '/api/action-field/save', // POST
    },
    actionInterface: {
        getInterfaceConfig: '/api/action-interface/config', // GET
        createInterface: '/api//action-interface/create', // POST
        updateInterface: '/api//action-interface/update', // PUT
    },
    actionCard: {
        getActionCard: '/api/card-config/detail', // GET
        updateActionCard: '/api/card-config/', // PUT
        createActionCard: '/api/card-config/', // POST
    },
    customAccount: {
        createCustomAccount: '/api/custom-account/create', // POST
        getCustomAccount: '/api/custom-account/', // GET
    },
    stepGuidance: {
        getStepConfig: '/api/step-guidance/', // GET
    },
    dynamicJs: {
        authDynamicJs: '/api/dynamic-js/debug/auth', // POST
        actionDynamicJs: '/api/dynamic-js/debug/action', // POST
        debugRender: '/api/dynamic-js/debug/render', // POST
    },
   
}

export  {requestUrl}