import Vue from 'vue'
import App from './App.vue'
import router from './router'

import AxiosInstance from "./http/http";

import ElementUI from 'element-ui'
import "./assets/less/theme/index.css"

import CKEditor from '@ckeditor/ckeditor5-vue2';

import { requestUrl } from "./http/requestUrl";

import directive from './assets/utils/directive'
import drag from './assets/utils/dragDiv';



Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.use(CKEditor);

Vue.use(drag);


Date.prototype.format = function (fmt) {
  const o = {
      "M+": this.getMonth() + 1, //月份
      "d+": this.getDate(), //日
      "h+": this.getHours(), //小时
      "m+": this.getMinutes(), //分
      "s+": this.getSeconds(), //秒
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度
      "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (const k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}


Vue.prototype.DATE_FORMAT = (refDate) => {
  return new Date(refDate).format('yyyy-MM-dd');
}
Vue.prototype.TIME_FORMAT = (refTime) => {
  console.debug('refTime', refTime)
  return new Date(refTime).format('hh:mm:ss');
}
Vue.prototype.isE = () =>{
  if(window.location.hostname.includes('ebot.isheely.com') || window.location.hostname.includes('localhost')) {
      return true
  }  else {
      return false
  }
}

 
/* 注册全局指令 */
Object.keys(directive).forEach(key => {
    Vue.directive(`${key}`, directive[key]);
});

function generateRandomString(length) {
  let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let values = new Uint32Array(length);
  window.crypto.getRandomValues(values);
  let str = '';
  for (let i = 0; i < length; i++) {
    str += charset[values[i] % charset.length];
  }
  return str;
}

Vue.prototype.$http = AxiosInstance;
Vue.prototype.requestUrl = requestUrl;
Vue.prototype.generateRandomString = generateRandomString;
Vue.prototype.defalutImageUrl = 'https://static.guoranbot.com/botProfilePhoto/10.png';

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
