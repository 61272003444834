<template>
    <div id="header-assembly">
        <slot name="setup-header-left">
            <span class="setup-header-left-icon"><i class="iconfont guoran-tongyichicun-shezhiquanxian"></i></span>
            <span>设置 - </span>
            <span>{{ headerTitle }}</span>
        </slot>
        <div class="setup-header-right">
            <slot class="setup-header-right-l" name="setup-header-right-l"></slot>
            <div class="setup-header-right-r">
                <div class="new-help-tips" @click="gotoHelp" v-if="uid != 20281 && uid != 117431">
                    <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                    <span>帮助中心</span>
                </div>

                <div class="help-document" @mouseenter="enterClick">
                    <el-tooltip class="help-item" effect="dark" placement="bottom" v-model="orderLength" :offset="-100">
                        <div v-if="false" slot="content">
                            <span v-show="showContent">上传下载中心</span>
                            <div v-show="showContentToop">
                                导出任务已提交,请前往<span @click="gotoDownLoad"
                                    style="color: #366AFF; cursor: pointer">下载中心</span>查看进度
                            </div>
                            <div v-show="deriveScreen">
                                导入任务已提交,请前往<span @click="goUpLoad" style="color: #366AFF; cursor: pointer">上传中心</span>查看进度
                            </div>
                        </div>
                        <el-link v-if="false" type="primary" class="help-link" :underline="false" @click="gotoDownLoad()">
                            <el-badge class="item">
                                <i class="iconfont guoran-a-24-02"></i>
                            </el-badge>
                        </el-link>
                    </el-tooltip>
                </div>

                <div v-if="false" class="person-info">
                    <el-dropdown @command="topHandleCommand">
                        <span class="el-dropdown-link">
                            <span class="head_portrait">
                                <el-avatar :size="37" :src="userInfo.profilePhoto
                                    | filterProfilePhoto
                                    "></el-avatar>
                            </span>
                        </span>
                        <el-dropdown-menu slot="dropdown" style="min-width: 150px">
                            <el-dropdown-item style="
                                    color: black;
                                    word-break: break-word;
                                    border-bottom: 2px solid #f5f8fb;
                                    line-height: 30px;
                                ">{{
                                    userInfo.realName
                                    ? userInfo.realName
                                    : userInfo.username
                                        ? userInfo.username
                                        : ""
                                }}</el-dropdown-item>
                            <el-dropdown-item command="profile"><i class="el-icon-user-solid"
                                    style="font-size: 14px"></i>个人信息</el-dropdown-item>
                            <el-dropdown-item command="logout"><i class="el-icon-switch-button"
                                    style="font-size: 14px"></i>退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-avatar :size="37" :src="userInfo.profilePhoto"></el-avatar>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { requestUrl } from "./../../http/requestUrl";

export default {
    name: "top-header",
    data() {
        return {
            userInfo: {
                profilePhoto:
                    "https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png",
            },
            roles: "",
            deriveScreen: false,
            showContentToop: false,
            showContent: false,
            tooltipContent: "上传下载中心",
            orderLength: false,
            rolePrivilegesList: [],
            showVersionEnt: false,
            versionInfoList: [],
            uid: ""
        };
    },
    props: ["circleUrl", "headerTitle", "upload", "showFilter"],
    mounted() {
        this.uid = localStorage.getItem('_uid')
        // this.rolePrivilegesList = this.getRolePrivileges();
        this.$nextTick(() => {
            setTimeout(() => {
                let userInfo = sessionStorage.getItem("userInfo");
                if (userInfo && userInfo !== "{}") {
                    this.userInfo = JSON.parse(userInfo);
                }
            }, 500);

            this.roles = localStorage.getItem("roleId");
            // this.getUserCompany();
        });
    },
    filters: {
        filterProfilePhoto(val) {
            let str = val;
            if (val == "" || val == null || val == "null") {
                str =
                    "https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png";
            }
            return str;
        },
    },
    methods: {
        // Copy成功
        onCopy(e) {
            console.log(e);
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError(e) {
            console.log(e);
            this.$message.error("抱歉，复制失败！");
        },
        getUserInfo() {
            let uid = localStorage.getItem("_uid");
            this.$http.get("/api/account/appoint/" + uid).then((res) => {
                if (res.data.code === "0") {
                    this.userInfo = res.data.data;
                    localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
                    setTimeout(() => {
                        let userInfo = localStorage.getItem("userInfo");
                        if (userInfo && userInfo !== "{}") {
                            this.userInfo = JSON.parse(userInfo);
                        }
                    }, 500);
                }
            })
        },
        // 跳转到帮助页面
        gotoHelp() {
            //window.open("https://showdoc.askbot.cn/web/#/5?page_id=24");
            this.$router.push({
                name: "help",
                params: {
                    helpSrc:
                        "https://showdoc.askbot.cn/web/#/readonly/6?page_id=43",
                },
            });
        },
        goToVersion(varsionInfo) {
            this.$router.push({ name: "version", params: varsionInfo });
        },
        topHandleCommand(command) {
            if (command === "logout") {
                this.$http.delete("/api/sso/auth/sign-out").then((res) => {
                    if (res.status === 200) {
                        this.$router.push({
                            name: "auth",
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            if (command === "profile") {
                this.$router.push({ path: "personal" });
            }
            if (command === "business") {
                let windo = window.open("_blank");
                windo.location = requestUrl[process.env.NODE_ENV].portal;
                windo.location = requestUrl[process.env.NODE_ENV].test;
            }
            if (command === "change") {
                window.open(requestUrl[process.env.NODE_ENV].portal);
            }
        },
        enterClick() {
            this.tooltipContent = "上传下载中心";
            this.showContent = true;
            this.showContentToop = false;
            this.deriveScreen = false;
            let cla = document.getElementsByClassName("is-dark");
            if (cla[cla.length] && cla[cla.length].style) {
                cla[cla.length].style.display = "block";
            }
        },
        showTip() {
            let cla = document.getElementsByClassName("is-dark");
            if (cla[cla.length] && cla[cla.length].style) {
                cla[cla.length].style.display = "block";
            }
            this.tooltipContent = "上传下载中心";
            this.showContent = false;
            this.showContentToop = false;
            this.deriveScreen = false;
            this.orderLength = false;
        },
        // 跳转到下载中心页面
        gotoDownLoad() {
            this.$router.push({ path: "downLoad" });
        },
        goUpLoad() {
            this.$router.push({ path: "upLoad" });
        },
        getUserCompany() {
            this.versionInfoList = [];
            this.$http
                .get(this.requestUrl.companyManage.getUserCompany)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            let modules = res.data.data.modules;
                            if (modules != null && modules.length > 0) {
                                modules.forEach((m) => {
                                    m.expiresIn = Number(m.expiresIn);
                                    m.beOverdueTime = Math.abs(Number(m.expiresIn));
                                    if (
                                        m.sysModule.code === "OPEN" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_OPEN"
                                        ) !== -1
                                    ) {
                                        this.versionInfoList.push(m);
                                    }
                                    // if (
                                    //     m.sysModule.name === "客服系统" &&
                                    //     this.rolePrivilegesList.indexOf(
                                    //         "PORTAL_ENTRANCE_IM"
                                    //     ) !== -1
                                    // ) {
                                    //     this.versionInfoList.push(m);
                                    // }
                                    if (
                                        m.sysModule.code === "WORKORDER" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_WORKORDER"
                                        ) !== -1
                                    ) {
                                        this.versionInfoList.push(m);
                                    }

                                });
                                this.showVersionEnt = true;
                                console.log(this.versionInfoList);
                            } else {
                                this.showVersionEnt = false;
                            }

                        }
                    }
                })
                .catch((res) => {
                    console.log(res);
                    // location.hash = "/auth";
                });
        },
        enterModule(redirectUrl) {
            let tempwindow = window.open("_blank");
            tempwindow.location = redirectUrl;
        },
    },
};
</script>

<style scoped lang="less">
.header-back {
    width: 62px;
    height: 62px;
    background-color: #aab9e1;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    margin-left: -15px;
    .iconfont {
        color: white;
        font-size: 18px;
    }
}

.ask-v-content {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    .ask-v-cell-tell {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 203px;
        height: 37px;
        line-height: 37px;
        background: #ffffff;
        border: 1px solid #a2baff;
        border-radius: 18px;
        text-align: center;

        .ask-v-cell-tell-c {
            color: #366aff;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .ask-v-cell {
        margin-top: 24px;

        .ask-v-cell-t {
            background: #e2e9ff;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;

            .ask-v-cell-t-l {
                flex: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 41px;

                .ask-v-icon {
                    margin-left: 12px;
                }

                .ask-v-name {
                    margin-left: 7px;
                    height: 41px;
                    font-size: 15px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #366aff;
                    line-height: 41px;
                }

                .ask-v-num {
                    margin-left: 6px;
                    padding: 0 6px;
                    height: 19px;
                    line-height: 20px;
                    background: #6089ff;
                    color: #f2f5fa;
                    border-radius: 9px;
                    font-size: 12px;
                }

                .be-overdue-time {
                    color: red;
                    font-size: 13px;
                }
            }

            .ask-v-link {
                flex: none;
                width: 70px;
                line-height: 41px;
                height: 41px;
                font-size: 12px;
                color: #366aff;
                cursor: pointer;

                i {
                    color: #366aff;
                    font-size: 2px;
                }
            }
        }

        .ask-v-cell-content {
            font-size: 13px;
            padding: 12px;
        }
    }
}

#header-assembly {
    background-color: #ffffff;
    height: 62px;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .setup-header-left {
        padding-left: 15px;
        font-size: 15px;
        color: #000000;
        display: flex;
        align-items: center;

        .setup-header-left-icon {
            margin-right: 9px;
        }
    }

    .setup-header-left-filter {
        flex: none;
        width: 34px;
        height: 34px;
        background-color: #f5f7fb;
        border-radius: 5px;
        margin-right: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        border: 1px solid #ffffff;

        .close-iconfont {
            position: absolute;
            top: -6px;
            right: -6px;

            i {
                font-size: 18px;
                color: #d2d8e3;
            }
        }

        i {
            color: #aab9e1;
        }
    }

    .setup-header-left-border {
        border: 1px solid #a1b9ff !important;

        i {
            color: #366aff !important;
        }
    }

    img {
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .setup-header-right {
        padding-right: 15px;
        display: flex;
        align-items: center;

        .new-help-tips {
            margin-right: 12px;
            margin-left: -8px;
            width: 80px;
            padding: 0 10px 0 6px;
            height: 29px;
            background: #ffffff;
            border: 1px solid #a1b9ff;
            border-radius: 17px;
            font-size: 14px;
            color: #366aff;
            cursor: pointer;
            display: flex;
            align-items: center;

            i {
                font-size: 10px !important;
                padding-right: 0px !important;
                color: #fff !important;
                margin-right: 4px;
                width: 20px;
                height: 20px;
                background: linear-gradient(224deg, #749bff, #366aff);
                border-radius: 50%;
                display: inline-block;
                line-height: 20px;
                text-align: center;
            }
        }

        .el-button {
            /deep/.iconfont {
                font-size: 14px !important;
            }
        }

        .ask-v-text {
            text-align: center;
            display: inline-block;
            flex: none;
            margin-right: 16px;
            font-size: 12px;
            width: 44px;
            height: 25px;
            line-height: 25px;
            background: #eff3ff;
            border-radius: 0px 13px 13px 14px;
            color: #6089ff;
            cursor: pointer;
        }

        .setup-header-right-l {
            padding-right: 20px;

            .el-button--primary {
                background-color: #366aff;
                border-color: #a1b9ff !important;
                border-radius: 17px;
                // font-size: 14px;
                font-weight: 600;

                .iconfont {
                    font-size: 14px !important;
                }
            }

            .el-button--default {
                color: #366aff;
                border-color: #a1b9ff !important;
                border-radius: 17px;
                // font-size: 14px;
                font-weight: 500;

                .iconfont {
                    font-size: 14px !important;
                }
            }

            .el-button--small {
                padding: 7px 15px 8px;
            }
        }

        .setup-header-right-r {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .person-info {
                height: 37px;
            }

            .iconfont {
                font-size: 24px;
                padding-right: 20px;
                color: #404040;
            }

            /deep/.el-badge__content {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background-color: #366aff;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            /deep/.el-badge__content.is-fixed {
                right: 0;
                left: -6px;
            }
        }

        /*.guoran-a-16-13{
            font-size: 14px;
        }*/
    }
}
</style>